/*Desktop View*/
.header {
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    height: 100px;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
  }
  .logo {
    width: 200px;
    height: 32px;
    background: url("../../../assets/imgs/logo-new.png") no-repeat;
  }
  .nav-options {
    padding-right: 25px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }
  
  .option :hover {
    color:red;
  }
  .mobile-menu {
    display: none;
  }
  .menu-icon{
    cursor: pointer;
    height: 40px;
    width:40px;
    color: black;
    display: none;
  }
  @media (max-width: 700px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;
    }
    .nav-options {
      display: flex;
      width: 100%;
      height: 250px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options.active {
      background: #9899d1;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 45px;
      height: 45px;
      display: unset;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }
    .sign-up {
      background: rgb(222, 9, 241);
      border-radius: 3px;
      color: white;
      padding: 20px 0px;
      width: 80%;
      align-self: center;
      border-radius: 3px;
    }
  
    .signin-up {
      display: none;
    }
  }
  .mobile-menu {
    display: block;
  }
  