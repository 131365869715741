.mainBox{
    margin-left: auto;
    margin-right: auto;
    width:80%;
    height:auto;
    text-align: left;
}
.masterHeader{
    height:auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size:50px ;
    font-weight: bolder;
}
.topImage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;   
}
.eduImg{
    /* height:auto; */
    max-width:60%;
}
.contactlink{
    border:1px solid;
    line-height:40px;
    padding:10px 50px 10px 50px;
    background:black;
    color:white;
}
.contactlink:hover{
    background: red;
}

@media (max-width: 1735px) {
    .masterHeader{
        flex-direction: column;
    }
  }
@media (max-width: 700px) {
    .masterHeader{
        font-size: 25px;
    }
    .eduImg{
        height:200px;
    }
  }