.contact-form {
    background: white;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    padding: 40px;
    text-align: left;
  }
  
  .contact-form-group {
    margin-bottom: 10px;
  }
  
  .contact-form-group label {
    display: block;
  }
  
  .contact-form-group input,
  .contact-form-group textarea {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
    padding:10px;
  }
  
  .contact-form-group textarea {
    height: 200px;
  }
  
  .contact-form button {
    cursor: pointer;
    padding: .8rem 1.5rem;
    margin-bottom: 10px;
  }
  
  .contact-form-notification {
    color: grey;
  }
  
  .contact-form-notification.contact-form-notification-failure {
    color: red;
  }
  
  .contact-form-notification.contact-form-notification-success {
    color: green;
  }
  .submitButton{
    width:100%;
    background:black;
    color: white;
    font-size: 15px;
  }
  .cancelButton{
    width:100%;
    background:white;
    color: black;
    font-size: 15px;
    border:2px solid black;
  }
  .uppertext{
    text-align: center;
  }
  .lowertext{
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    justify-content: space-between;
  }
  .lowerfooter{
    background:black;
    padding:40px;
    text-align: center;
    margin-top:40px;
  }
  @media (max-width: 1000px) {
    .contact-form {
    max-width: 100%;
   }
}
@media (max-width: 600px) {
  .lowertext{
    flex-direction: column;
  }
}