.mainBox{
    margin-left: auto;
    margin-right: auto;
    width:80%;
    height:auto;
    text-align: left;
}
.mainImg {
    margin-left: auto;
    margin-right: auto;
    width:100%;
    height:auto;
}
.navOptions {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 50px;
    list-style-type: none;
    font-size: 20px;
    padding-bottom:60px ;
    border-bottom: 1px solid black;
}
.option :hover {
    color:red;
}
.productBox{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
}
.productLogo{
    margin-bottom: 30px;
}
.h1{
    display: flex;
    flex-direction: row ;
    font-size: 40px;
    font-weight:bolder;
    color:red;
    line-height: 80px;
}
.h2{
    font-weight:500px;
    margin-bottom: 40px;
}
.p1{
    color:red;
    margin-bottom: 30px;
    line-height: 30px;
    font-size: 18px;
}
.p2{
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
}
.p3{
    font-weight: normal;
    line-height: 30px;
    font-size: 18px;
}

@media (max-width: 900px) {
    .navOptions {
    grid-template-columns: repeat(3, auto);
   }
}
@media (max-width: 700px) {
    .navOptions {
    grid-template-columns: repeat(2, auto);
   }
}
