.mainBox{
    margin-left: auto;
    margin-right: auto;
    width:80%;
    height:auto;
    text-align: left;
}
.masterHeader{
    height:auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size:50px ;
    font-weight: bolder;
}
.topImage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;   
}
.eduImg{
    height:100%;
    max-width:60%;
}
.contactlink{
    border:1px solid;
    line-height:40px;
    padding:10px 50px 10px 50px;
    background:black;
    color:white;
}
.contactlink:hover{
    background: red;
}
.lowertext{
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
    font-size: 20px;
    padding-bottom:60px ;
    border-bottom: 1px solid black;
    margin-bottom: 40px;
}

@media (max-width: 1735px) {
    .masterHeader{
        flex-direction: column;
    }
}
@media (max-width: 700px) {
    .masterHeader{
        font-size: 25px;
    }
    .eduImg{
        height:200px;
    }
}
@media (max-width: 800px) {
    .lowertext{
        grid-template-columns: repeat(2, auto);
    }
}
@media (max-width: 500px) {
    .lowertext{
        grid-template-columns: repeat(1, auto);
    }
}